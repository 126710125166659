import ViewVideo from "../components/ViewVideo";

const Videos = () => {
  return (
    <div>
      <ViewVideo />
    </div>
  );
};

export default Videos;
